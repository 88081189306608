import axios from "axios";
import { AllContent } from "../types";
import { Category, Subcategory } from "../types/categories";
import { Tag } from "../types/tags";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

console.log(API_URL);

export const fetchTags = async (): Promise<Tag[]> => {
  try {
    const response = await axios.get(`${API_URL}/items/tags`, {
      params: {
        fields: ["translations.*", "*.*"],
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const fetchCategoriesAndCourses = async (): Promise<{
  categories: Category[];
  subcategories: Subcategory[];
}> => {
  try {
    const [categoriesResponse, subcategoriesResponse] = await Promise.all([
      axios.get(`${API_URL}/items/categories`, {
        params: {
          fields: ["id", "translations.*", "slug"],
        },
      }),
      axios.get(`${API_URL}/items/subcategories`, {
        params: {
          fields: ["id", "translations.*", "categories.categories_id"],
        },
      }),
    ]);

    return {
      categories: categoriesResponse.data.data,
      subcategories: subcategoriesResponse.data.data,
    };
  } catch (error) {
    console.error("Error fetching categories and subcategories:", error);
    throw error;
  }
};

interface FetchFilteredAllContentProps {
  pageParam: number;
  categoryId: string | null;
  subcategoryIds: string[];
  filters: any;
  selectedYears: number[];
}

// export const fetchFilteredAllContent = async ({
//   pageParam = 1,
//   categoryId,
//   subcategoryIds,
//   filters,
//   selectedYears,
// }: FetchFilteredAllContentProps): Promise<{
//   data: AllContent[];
//   nextPage: number | undefined;
//   totalItems: number;
// }> => {
//   const limit = 10;
//   let params: any = {
//     fields: [
//       "id",
//       "date_created",
//       "translations.*",
//       "archive",
//       "years",
//       "years.years_id.year",
//       "cover",
//       "category.id",
//       "category.translations.*",
//       "category.slug",
//       "subcategories.subcategories_id",
//       "subcategories.subcategories_id.translations.*",
//       "subcategories.translations.*",
//       "tags.tags_id.translations.*",
//       "tags.tags_id",
//       "tags.translations.*",
//       "slug",
//     ],
//     sort: ["-date_created"],
//     page: pageParam,
//     limit: limit,
//   };

//   let filterConditions = [];

//   // Додаємо фільтр по роках тільки якщо вони вибрані
//   if (selectedYears && selectedYears.length > 0) {
//     filterConditions.push({
//       years: {
//         years_id: {
//           year: {
//             _in: selectedYears,
//           },
//         },
//       },
//     });
//   }

//   if (categoryId) {
//     filterConditions.push({
//       category: {
//         id: {
//           _eq: categoryId,
//         },
//       },
//     });
//   }

//   if (subcategoryIds.length > 0) {
//     filterConditions.push({
//       subcategories: {
//         subcategories_id: {
//           _in: subcategoryIds,
//         },
//       },
//     });
//   }

//   if (filters) {
//     if (filters.archiveStatus === "current") {
//       filterConditions.push({ archive: { _null: true } });
//     } else if (filters.archiveStatus === "archive") {
//       filterConditions.push({ archive: { _nnull: true } });
//     }

//     const activeTagIds = Object.entries(filters)
//       .filter(([key, value]) => key !== "archiveStatus" && value)
//       .map(([key]) => key);

//     if (activeTagIds.length > 0) {
//       filterConditions.push({
//         tags: {
//           tags_id: {
//             id: {
//               _in: activeTagIds,
//             },
//           },
//         },
//       });
//     }
//   }

//   // Додаємо фільтри тільки якщо вони є
//   if (filterConditions.length > 0) {
//     params.filter = {
//       _and: filterConditions,
//     };
//   }

//   console.log(
//     "Years filter condition:",
//     selectedYears && selectedYears.length > 0
//       ? JSON.stringify(
//           filterConditions.find((f) => f.years),
//           null,
//           2
//         )
//       : "No year filter"
//   );
//   console.log("Final request params:", JSON.stringify(params, null, 2));

//   try {
//     const response = await axios.get(`${API_URL}/items/allContent`, {
//       params,
//     });

//     console.log("API Response:", {
//       totalCount: response.data.meta?.total_count,
//       dataLength: response.data.data.length,
//       firstItemYears: response.data.data[0]?.years,
//     });

//     return {
//       data: response.data.data,
//       nextPage: response.data.data.length === limit ? pageParam + 1 : undefined,
//       totalItems: response.data.meta?.total_count || response.data.data.length,
//     };
//   } catch (error) {
//     console.error("Error fetching all content:", error);
//     throw error;
//   }
// };

interface FetchFilteredAllContentProps {
  pageParam: number;
  categoryId: string | null;
  subcategoryIds: string[];
  filters: any;
  selectedYears: number[];
}
export const fetchFilteredAllContent = async ({
  pageParam = 1,
  categoryId,
  subcategoryIds,
  filters,
  selectedYears,
}: FetchFilteredAllContentProps): Promise<{
  data: AllContent[];
  nextPage: number | undefined;
  totalItems: number;
}> => {
  // console.log("fetchFilteredAllContent called with:", {
  //   pageParam,
  //   categoryId,
  //   subcategoryIds,
  //   filters,
  //   selectedYears,
  // });

  const limit = 10;
  let params: any = {
    fields: [
      "id",
      "date_created",
      "translations.*",
      "archive",
      "years",
      "years.years_id.year",
      "cover",
      "category.id",
      "category.translations.*",
      "category.slug",
      "subcategories.subcategories_id",
      "subcategories.subcategories_id.translations.*",
      "subcategories.translations.*",
      "tags.tags_id.translations.*",
      "tags.tags_id",
      "tags.translations.*",
      "slug",
    ],
    sort: ["-date_created"],
    page: pageParam,
    limit: limit,
  };

  let filterConditions = [];

  if (selectedYears && selectedYears.length > 0) {
    const yearFilter = {
      years: {
        years_id: {
          year: {
            _in: selectedYears,
          },
        },
      },
    };
    filterConditions.push(yearFilter);
    console.log("Added year filter:", yearFilter);
  }

  if (categoryId) {
    filterConditions.push({
      category: {
        id: {
          _eq: categoryId,
        },
      },
    });
  }

  if (subcategoryIds.length > 0) {
    filterConditions.push({
      subcategories: {
        subcategories_id: {
          _in: subcategoryIds,
        },
      },
    });
  }

  if (filters) {
    if (filters.archiveStatus === "current") {
      filterConditions.push({ archive: { _null: true } });
    } else if (filters.archiveStatus === "archive") {
      filterConditions.push({ archive: { _nnull: true } });
    }

    const activeTagIds = Object.entries(filters)
      .filter(([key, value]) => key !== "archiveStatus" && value)
      .map(([key]) => key);

    if (activeTagIds.length > 0) {
      filterConditions.push({
        tags: {
          tags_id: {
            id: {
              _in: activeTagIds,
            },
          },
        },
      });
    }
  }

  if (filterConditions.length > 0) {
    params.filter = {
      _and: filterConditions,
    };
  }

  // console.log("Final request params:", JSON.stringify(params, null, 2));

  try {
    // console.log("Making API request to:", `${API_URL}/items/allContent`);
    const response = await axios.get(`${API_URL}/items/allContent`, {
      params,
    });

    // console.log("API Response:", {
    //   totalCount: response.data.meta?.total_count,
    //   dataLength: response.data.data.length,
    //   firstItemYears: response.data.data[0]?.years,
    // });

    return {
      data: response.data.data,
      nextPage: response.data.data.length === limit ? pageParam + 1 : undefined,
      totalItems: response.data.meta?.total_count || response.data.data.length,
    };
  } catch (error) {
    console.error("API Error:", error);
    if (axios.isAxiosError(error)) {
      console.error("API Error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
    }
    throw error;
  }
};

// interface AllContent {
//   id: number;
//   date_created: string;
//   archive: boolean | null;
//   cover: string | null;
//   slug: string;
//   translations: Array<{
//     id: number;
//     allContent_id: number;
//     languages_code: string;
//     title: string;
//     description: string | null;
//     button_name: string;
//     date_tag: string | null;
//     grids_filter: Array<{
//       title: string;
//       slug: string;
//     }> | null;
//   }>;
//   years: Array<{
//     years_id: {
//       year: number;
//     };
//   }>;
//   category: {
//     id: string;
//     slug: string;
//     translations: Array<{
//       id: number;
//       categories_id: string;
//       languages_code: string;
//       category_name: string;
//     }>;
//   } | null;
//   subcategories: Array<{
//     subcategories_id: {
//       translations: Array<{
//         id: number;
//         subcategories_id: number;
//         languages_code: string;
//         subcategory_name: string;
//       }>;
//     };
//   }>;
//   tags: Array<{
//     tags_id: {
//       translations: Array<{
//         id: number;
//         tags_id: string;
//         languages_code: string;
//         tag: string;
//       }>;
//     };
//   }>;
// }

// export const fetchFilteredAllContent = async ({
//   pageParam = 1,
//   categoryId,
//   subcategoryIds,
//   filters,
//   selectedYears,
// }: FetchFilteredAllContentProps): Promise<{
//   data: AllContent[];
//   nextPage: number | undefined;
//   totalItems: number;
// }> => {
//   const limit = 10;
//   let params: any = {
//     fields: [
//       "id",
//       "date_created",
//       "translations.*",
//       "archive",
//       "years",
//       "years.years_id.year",
//       "cover",
//       "category.id",
//       "category.translations.*",
//       "category.slug",
//       "subcategories.subcategories_id",
//       "subcategories.subcategories_id.translations.*",
//       "subcategories.translations.*",
//       "tags.tags_id.translations.*",
//       "tags.tags_id",
//       "tags.translations.*",
//       "slug",
//     ],
//     sort: ["-date_created"],
//     page: pageParam,
//     limit: limit,
//   };

//   let filterConditions = [];

//   // Оновлена логіка фільтрації по роках
//   if (selectedYears && selectedYears.length > 0) {
//     filterConditions.push({
//       years: {
//         years_id: {
//           year: {
//             _in: selectedYears,
//           },
//         },
//       },
//     });

//     // Додаємо лог для перевірки умови фільтрації
//     console.log(
//       "Years filter condition:",
//       JSON.stringify(filterConditions[filterConditions.length - 1], null, 2)
//     );
//   }

//   if (categoryId) {
//     filterConditions.push({
//       category: {
//         id: {
//           _eq: categoryId,
//         },
//       },
//     });
//   }

//   if (subcategoryIds.length > 0) {
//     filterConditions.push({
//       subcategories: {
//         subcategories_id: {
//           _in: subcategoryIds,
//         },
//       },
//     });
//   }

//   if (filters) {
//     if (filters.archiveStatus === "current") {
//       filterConditions.push({ archive: { _null: true } });
//     } else if (filters.archiveStatus === "archive") {
//       filterConditions.push({ archive: { _nnull: true } });
//     }

//     const activeTagIds = Object.entries(filters)
//       .filter(([key, value]) => key !== "archiveStatus" && value)
//       .map(([key]) => key);

//     if (activeTagIds.length > 0) {
//       filterConditions.push({
//         tags: {
//           tags_id: {
//             id: {
//               _in: activeTagIds,
//             },
//           },
//         },
//       });
//     }
//   }

//   if (filterConditions.length > 0) {
//     params.filter = {
//       _and: filterConditions,
//     };
//   }

//   // Додаємо лог фінальних параметрів
//   console.log("Final request params:", JSON.stringify(params, null, 2));

//   try {
//     const response = await axios.get(`${API_URL}/items/allContent`, {
//       params,
//     });

//     // Додаємо лог відповіді
//     console.log("API Response:", {
//       totalCount: response.data.meta?.total_count,
//       dataLength: response.data.data.length,
//       firstItemYears: response.data.data[0]?.years,
//     });

//     return {
//       data: response.data.data,
//       nextPage: response.data.data.length === limit ? pageParam + 1 : undefined,
//       totalItems: response.data.meta?.total_count || response.data.data.length,
//     };
//   } catch (error) {
//     console.error("Error fetching all content:", error);
//     throw error;
//   }
// };

// export interface FetchFilteredAllContentParams {
//   pageParam?: number;
//   categoryId: string | null;
//   subcategoryIds: string[];
//   filters: any;
//   selectedYear: number | null;
// }

// interface FetchFilteredAllContentProps {
//   pageParam: number;
//   categoryId: string | null;
//   subcategoryIds: string[];
//   filters: any;
//   selectedYears: number[]; // Змінено на масив
// }

// export const fetchFilteredAllContent = async ({
//   pageParam = 1,
//   categoryId,
//   subcategoryIds,
//   filters,
//   selectedYears, // Змінено з selectedYear на selectedYears
// }: FetchFilteredAllContentProps): Promise<{
//   data: AllContent[];
//   nextPage: number | undefined;
//   totalItems: number;
// }> => {
//   const limit = 10;
//   let params: any = {
//     fields: [
//       "id",
//       "date_created",
//       "translations.*",
//       "archive",
//       "years",
//       "years.years_id.year",
//       "cover",
//       "category.id",
//       "category.translations.*",
//       "category.slug",
//       "subcategories.subcategories_id",
//       "subcategories.subcategories_id.translations.*",
//       "subcategories.translations.*",
//       "tags.tags_id.translations.*",
//       "tags.tags_id",
//       "tags.translations.*",
//       "slug",
//     ],
//     sort: ["-date_created"],
//     page: pageParam,
//     limit: limit,
//   };

//   let filterConditions = [];

//   if (selectedYears && selectedYears.length > 0) {
//     // Перевіряємо наявність вибраних років
//     filterConditions.push({
//       years: {
//         years_id: {
//           year: {
//             _in: selectedYears, // Використовуємо _in для множинного вибору
//           },
//         },
//       },
//     });
//   }

//   if (categoryId) {
//     filterConditions.push({
//       category: {
//         id: {
//           _eq: categoryId,
//         },
//       },
//     });
//   }

//   if (subcategoryIds.length > 0) {
//     filterConditions.push({
//       subcategories: {
//         subcategories_id: {
//           _in: subcategoryIds,
//         },
//       },
//     });
//   }

//   if (filters) {
//     if (filters.archiveStatus === "current") {
//       filterConditions.push({ archive: { _null: true } });
//     } else if (filters.archiveStatus === "archive") {
//       filterConditions.push({ archive: { _nnull: true } });
//     }

//     const activeTagIds = Object.entries(filters)
//       .filter(([key, value]) => key !== "archiveStatus" && value)
//       .map(([key]) => key);

//     if (activeTagIds.length > 0) {
//       filterConditions.push({
//         tags: {
//           tags_id: {
//             id: {
//               _in: activeTagIds,
//             },
//           },
//         },
//       });
//     }
//   }

//   if (filterConditions.length > 0) {
//     params.filter = {
//       _and: filterConditions,
//     };
//   }

//   try {
//     const response = await axios.get(`${API_URL}/items/allContent`, {
//       params,
//     });

//     return {
//       data: response.data.data,
//       nextPage: response.data.data.length === limit ? pageParam + 1 : undefined,
//       totalItems: response.data.meta?.total_count || response.data.data.length,
//     };
//   } catch (error) {
//     console.error("Error fetching all content:", error);
//     throw error;
//   }
// };

// export const fetchFilteredAllContent = async ({
//   pageParam = 1,
//   categoryId,
//   subcategoryIds,
//   filters,
//   selectedYear,
// }: FetchFilteredAllContentParams): Promise<{
//   data: AllContent[];
//   nextPage: number | undefined;
//   totalItems: number;
// }> => {
//   const limit = 10;
//   let params: any = {
//     fields: [
//       "id",
//       "date_created",
//       "translations.*",
//       "archive",
//       "years",
//       "years.years_id.year",
//       "cover",
//       "category.id",
//       "category.translations.*",
//       "category.slug",
//       "subcategories.subcategories_id",
//       "subcategories.subcategories_id.translations.*",
//       "subcategories.translations.*",
//       "tags.tags_id.translations.*",
//       "tags.tags_id",
//       "tags.translations.*",
//       "slug",
//     ],
//     sort: ["-date_created"],
//     page: pageParam,
//     limit: limit,
//   };

//   let filterConditions = [];

//   if (selectedYear) {
//     filterConditions.push({
//       years: {
//         years_id: {
//           year: {
//             _eq: selectedYear,
//           },
//         },
//       },
//     });
//   }

//   if (categoryId) {
//     filterConditions.push({
//       category: {
//         id: {
//           _eq: categoryId,
//         },
//       },
//     });
//   }

//   if (subcategoryIds.length > 0) {
//     filterConditions.push({
//       subcategories: {
//         subcategories_id: {
//           _in: subcategoryIds,
//         },
//       },
//     });
//   }

//   if (filters) {
//     if (filters.archiveStatus === "current") {
//       filterConditions.push({ archive: { _null: true } });
//     } else if (filters.archiveStatus === "archive") {
//       filterConditions.push({ archive: { _nnull: true } });
//     }

//     const activeTagIds = Object.entries(filters)
//       .filter(([key, value]) => key !== "archiveStatus" && value)
//       .map(([key]) => key);

//     if (activeTagIds.length > 0) {
//       filterConditions.push({
//         tags: {
//           tags_id: {
//             id: {
//               _in: activeTagIds,
//             },
//           },
//         },
//       });
//     }
//   }

//   if (filterConditions.length > 0) {
//     params.filter = {
//       _and: filterConditions,
//     };
//   }

//   try {
//     const response = await axios.get(`${API_URL}/items/allContent`, {
//       params,
//     });

//     return {
//       data: response.data.data,
//       nextPage: response.data.data.length === limit ? pageParam + 1 : undefined,
//       totalItems: response.data.meta?.total_count || response.data.data.length,
//     };
//   } catch (error) {
//     console.error("Error fetching all content:", error);
//     throw error;
//   }
// };

export const fetchAllContentDetails = async (slug: string) => {
  try {
    const pagesResponse = await axios.get(`${API_URL}/items/allContent`, {
      params: {
        filter: {
          slug: {
            _eq: slug,
          },
        },
        fields: [
          "slug",
          "status",
          "translations.*",
          "tiles.*",
          "tiles.translations.*",
          // "grids_filter",
        ],
      },
    });

    console.log(pagesResponse.data.data[0]);

    const pagesData = pagesResponse.data.data[0];

    return pagesData;
  } catch (error) {
    console.error("Error fetching course details:", error);
    throw error;
  }
};

export const fetchGrids = async (slug: string, filter: string) => {
  try {
    const pagesResponse = await axios.get(`${API_URL}/items/allContent`, {
      params: {
        filter: {
          slug: {
            _eq: slug,
          },
        },
        fields: ["slug", "grids", "grids_filter"],
      },
    });

    const grid = pagesResponse.data.data[0].grids.filter(
      (item: any) => item.slug === filter
    );

    console.log("Grid", grid);

    return grid;
  } catch (error) {
    console.error("Error fetching course details:", error);
    throw error;
  }
};

export const searchAllContent = async (
  searchQuery: string
): Promise<AllContent[]> => {
  if (searchQuery.length < 2) {
    return [];
  }

  const params = {
    fields: [
      "id",
      "translations.*",
      "category.id",
      "category.slug",
      "category.translations.*",
      "subcategories.subcategories_id.translations.*",
      "subcategories.subcategories_id",
      "subcategories.translations.*",
      "tags.tags_id.translations.*",
      "tags.tags_id",
      "tags.translations.*",
      // "start_date",
      // "end_date",
      "slug",
    ],
    filter: {
      _or: [
        {
          translations: {
            title: {
              _contains: searchQuery,
            },
          },
        },
        {
          translations: {
            description: {
              _contains: searchQuery,
            },
          },
        },
      ],
    },
    limit: 10,
  };

  try {
    const response = await axios.get(`${API_URL}/items/allContent`, { params });

    return response.data.data;
  } catch (error) {
    console.error("Error searching content:", error);
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", error.response?.data);
    }
    throw error;
  }
};
