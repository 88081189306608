import { useQuery } from "react-query";
import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export interface GridFilter {
  id?: string;
  title?: string;
  slug?: string;
}

interface Translation {
  allContent_id: number;
  button_name: string;
  date_tag: string | null;
  description: string | null;
  grids_filter: GridFilter[];
  id: number;
  languages_code: string;
  title: string;
  [key: string]: any;
}

interface TileTranslation {
  id: number;
  tiles_id: string;
  languages_code: string;
  text: string;
}

export interface Template {
  id: string;
  title: string | null;
  image: string | null;
  description: string | null;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  sort: number | null;
  page: string;
  video_url: string | null;
  type: string;
  translations: TileTranslation[];
}

interface Page {
  slug: string;
  status: string;
  sort: number | null;
  date_created: string;
  date_updated: string;
  translations: Translation[];
  template: Template[];
}

interface AllContentDetails {
  id: string | number;
  slug: string;
  translations: Translation[];
  pageDetails?: Page;
  template: Template[];
  tiles?: Template[];
  category?: any;
}

export const fetchAllContentDetails = async (
  slug: string
): Promise<AllContentDetails> => {
  try {
    const response = await axios.get(`${API_URL}/items/allContent`, {
      params: {
        filter: { slug: { _eq: slug } },
        fields: [
          "id",
          "slug",
          "status",
          "translations.*",
          "tiles.*",
          "tiles.translations.*",
          "category.*",
        ],
      },
    });

    return response.data.data[0];
  } catch (error) {
    console.error("Error fetching content details:", error);
    throw error;
  }
};

interface GridItem {
  slug: string;
  template: Template[];
}

export const fetchGrids = async (
  slug: string,
  filter: string
): Promise<GridItem[]> => {
  try {
    const response = await axios.get(`${API_URL}/items/allContent`, {
      params: {
        filter: { slug: { _eq: slug } },
        fields: ["slug", "grids.*.*", "grids.template.translations.*"],
      },
    });
    const grids = response.data.data[0].grids;

    const responseGrid = grids.filter((item: any) => item.slug === filter);

    return responseGrid;
  } catch (error) {
    console.error("Error fetching grids:", error);
    throw error;
  }
};

export const useAllContentDetails = (slug: string | undefined) => {
  return useQuery<AllContentDetails, Error>(
    ["contentDetails", slug],
    () => fetchAllContentDetails(slug as string),
    { enabled: !!slug }
  );
};

export const useGrids = (
  slug: string | undefined,
  activeFilter: string | null
) => {
  return useQuery<GridItem[], Error>(
    ["grids", slug, activeFilter],
    () => fetchGrids(slug as string, activeFilter as string),
    { enabled: !!slug && !!activeFilter }
  );
};

export default useAllContentDetails;
