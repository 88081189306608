import { useState, useEffect, useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";
import axios from "axios";
import { AllContent } from "../types";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const useSavedContent = () => {
  const [savedContentIds, setSavedContentIds] = useLocalStorage<
    (number | string)[]
  >("savedContentIds", []);
  const [savedContent, setSavedContent] = useState<AllContent[]>([]);
  const [error, setError] = useState<string | null>(null);

  const addSavedContent = useCallback(
    (content: AllContent) => {
      setSavedContentIds((prev) => [...prev, content.id]);
      setSavedContent((prev) => [...prev, content]);
    },
    [setSavedContentIds]
  );

  const removeSavedContent = useCallback(
    (id: number | string) => {
      setSavedContentIds((prev) =>
        prev.filter((contentId) => contentId !== id)
      );
      setSavedContent((prev) => prev.filter((content) => content.id !== id));
    },
    [setSavedContentIds]
  );

  useEffect(() => {
    const fetchSavedContent = async () => {
      if (savedContentIds.length === 0) {
        setSavedContent([]);
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/items/allContent`, {
          params: {
            filter: { id: { _in: savedContentIds } },
            fields: [
              "*",
              "category.*",
              "category.translations.*",
              "translations.*",
              "subcategories.*",
            ],
          },
        });
        setSavedContent(response.data.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching saved content:", error);
        setError("Failed to fetch saved content");
      }
    };

    fetchSavedContent();
  }, [savedContentIds]);

  return {
    savedContent,
    savedContentIds,
    addSavedContent,
    removeSavedContent,
    error,
  };
};
